var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "form-element-textarea-demo" } },
    [
      _c("textarea-default"),
      _c("textarea-label"),
      _c("textarea-counter"),
      _c("textarea-width"),
      _c("textarea-height"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }