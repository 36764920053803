var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Counter", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "There are times when we need the user to only enter a certain number of characters for it, we have the property "
        ),
        _c("code", [_vm._v("counter")]),
        _vm._v(", the value is a number and determines the maximum"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "To get when the number of characters is exceeded use the property "
            ),
            _c("code", [_vm._v("counter-danger.sync")]),
          ]),
        ]
      ),
      _c("br"),
      _c(
        "div",
        [
          _c("vs-textarea", {
            attrs: {
              counter: "20",
              label: "Counter: 20",
              "counter-danger": _vm.counterDanger,
            },
            on: {
              "update:counterDanger": function ($event) {
                _vm.counterDanger = $event
              },
              "update:counter-danger": function ($event) {
                _vm.counterDanger = $event
              },
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <vs-textarea counter="20" label="Counter: 20" :counter-danger.sync="counterDanger" v-model="textarea" />\n  </div>\n</template>\n\n<script>\nexport default {\n  data:()=>({\n    textarea: \'\',\n    counterDanger: false\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }