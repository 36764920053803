var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Height", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("You can set the height of the textarea with the "),
        _c("code", [_vm._v("height")]),
        _vm._v(" prop."),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-textarea", {
            attrs: { label: "Height set to 200px", height: "200px" },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <vs-textarea v-model="textarea" label="Height set to 200px" height="200px" />\n  </div>\n</template>\n\n<script>\nexport default {\n  data:()=>({\n    textarea: \'\',\n  })\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }