var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Label", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("If you need to add a label you can use the label property"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-textarea", {
            attrs: { label: "Label in Textarea" },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <vs-textarea label="Label in Textarea" v-model="textarea" />\n  </div>\n</template>\n\n<script>\nexport default {\n  data:()=>({\n    textarea: \'\',\n  })\n}\n</script>\n\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }